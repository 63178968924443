import { useState, useEffect } from 'react';

const UseDetectHeight = () => {
  const [detectHeight, setDetectHeight] = useState(false);

  useEffect(() => {
    const detectInnerHeight = () => {
      window.pageYOffset > 100 ? setDetectHeight(true) : setDetectHeight(false);
    };
    window.addEventListener('scroll', detectInnerHeight);
    return () => window.removeEventListener('scroll', detectInnerHeight);
  }, []);

  return [detectHeight];
};

export default UseDetectHeight;
