import React, { useState, useContext } from 'react';

const PaginationContext = React.createContext();

export const PaginationProvider = ({ children }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [arrayOfAllArticles, setArrayOfAllArticles] = useState([]);
  const articlesPerPage = 5;

  const lastArticleIndex = currentPage * articlesPerPage;
  const firstArticleIndex = lastArticleIndex - articlesPerPage;

  const currentArticles = arrayOfAllArticles.slice(
    firstArticleIndex,
    lastArticleIndex,
  );

  let pages = [];
  for (
    let i = 1;
    i <= Math.ceil(arrayOfAllArticles.length / articlesPerPage);
    i++
  ) {
    pages.push(i);
  }

  return (
    <PaginationContext.Provider
      value={{
        setCurrentPage,
        setArrayOfAllArticles,
        currentPage,
        arrayOfAllArticles,
        currentArticles,
        pages,
      }}
    >
      {children}
    </PaginationContext.Provider>
  );
};

export const usePaginationContext = () => {
  return useContext(PaginationContext);
};
