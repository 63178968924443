import React from 'react';
import Form from './form';
import SectionTitle from './sectionTitle';
import { AiOutlineMail, AiOutlineMobile } from 'react-icons/ai';
import { StaticImage } from 'gatsby-plugin-image';
import { FormattedMessage } from 'react-intl';

const ContactSection = () => {
  return (
    <section>
      <SectionTitle>
        <FormattedMessage id='contact-us' />
      </SectionTitle>
      <div className='w-full h-[180px] md:h-[400px]'>
        <StaticImage
          src='../images/kontakt.webp'
          alt='karta-pobytu'
          className='w-full h-full'
          placeholder='blurred'
        />
      </div>
      <div className='text-md xl:text-lg my-16 text-center flex flex-col items-center gap-4'>
        <div>
          <p>
            <FormattedMessage id='contact-info' />
          </p>
        </div>
        <div className='flex gap-4'>
          <a
            href='mailto:biuro@gracehome.pl'
            className='flex items-center text-primaryColor hover:text-hoverColor'
          >
            <AiOutlineMail className='mr-1' />
            <span>biuro@gracehome.pl</span>
          </a>
          <a
            href='tel:+48605505308'
            className='flex items-center text-primaryColor hover:text-hoverColor'
          >
            <AiOutlineMobile />
            <span>605505308</span>
          </a>
        </div>
      </div>
      <Form />
    </section>
  );
};

export default ContactSection;
