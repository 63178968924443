import React from 'react';
import { AiOutlineArrowUp } from 'react-icons/ai';
import UseDetectHeight from '../hooks/useDetectHeight';
import { useDetectScroll } from '@smakss/react-scroll-direction';

// alternative component to add
const ScrollToTopBtn = () => {
  const [detectHeight] = UseDetectHeight();
  const [scrollDir] = useDetectScroll({});

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div
      className={`fixed bottom-[40px] right-[40px] transition-all translate-y-[100px] duration-500 ${
        detectHeight && scrollDir === 'down'
          ? 'visible opacity-100  translate-y-[0]'
          : 'invisible opacity-0'
      }`}
    >
      <button
        type='button'
        className='text-bgColor hover:text-hoverColor transition bg-primaryColor w-[60px] h-[60px] cursor-pointer rounded-[50%] flex justify-center items-center shadow-xl'
        onClick={handleScrollToTop}
      >
        <AiOutlineArrowUp className='text-3xl ' />
      </button>
    </div>
  );
};

export default ScrollToTopBtn;
