import React from 'react';

const SectionTitle = ({ children }) => {
  return (
    <div className='flex justify-center items-center gap-5 mb-[100px] lg:mb-[140px] text-center'>
      <span className='w-full h-[2px] bg-hoverColor hidden md:flex' />
      <div>
        <h2 className='sm:whitespace-nowrap text-3xl md:text-4xl uppercase text-primaryColor'>
          {children}
        </h2>
      </div>
      <span className='w-full h-[2px] bg-hoverColor hidden md:flex' />
    </div>
  );
};

export default SectionTitle;
