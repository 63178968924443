exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktualnosci-jsx": () => import("./../../../src/pages/aktualnosci.jsx" /* webpackChunkName: "component---src-pages-aktualnosci-jsx" */),
  "component---src-pages-biuro-nieruchomosci-doradztwo-kredytowe-jsx": () => import("./../../../src/pages/biuro-nieruchomosci/doradztwo-kredytowe.jsx" /* webpackChunkName: "component---src-pages-biuro-nieruchomosci-doradztwo-kredytowe-jsx" */),
  "component---src-pages-biuro-nieruchomosci-fundacja-jsx": () => import("./../../../src/pages/biuro-nieruchomosci/fundacja.jsx" /* webpackChunkName: "component---src-pages-biuro-nieruchomosci-fundacja-jsx" */),
  "component---src-pages-biuro-nieruchomosci-informacje-jsx": () => import("./../../../src/pages/biuro-nieruchomosci/informacje.jsx" /* webpackChunkName: "component---src-pages-biuro-nieruchomosci-informacje-jsx" */),
  "component---src-pages-biuro-nieruchomosci-inwestycja-w-nieruchomosci-jsx": () => import("./../../../src/pages/biuro-nieruchomosci/inwestycja-w-nieruchomosci.jsx" /* webpackChunkName: "component---src-pages-biuro-nieruchomosci-inwestycja-w-nieruchomosci-jsx" */),
  "component---src-pages-biuro-nieruchomosci-sprzedaz-dzialek-jsx": () => import("./../../../src/pages/biuro-nieruchomosci/sprzedaz-dzialek.jsx" /* webpackChunkName: "component---src-pages-biuro-nieruchomosci-sprzedaz-dzialek-jsx" */),
  "component---src-pages-biuro-nieruchomosci-sprzedaz-nieruchomosci-jsx": () => import("./../../../src/pages/biuro-nieruchomosci/sprzedaz-nieruchomosci.jsx" /* webpackChunkName: "component---src-pages-biuro-nieruchomosci-sprzedaz-nieruchomosci-jsx" */),
  "component---src-pages-biuro-nieruchomosci-tours-jsx": () => import("./../../../src/pages/biuro-nieruchomosci/tours.jsx" /* webpackChunkName: "component---src-pages-biuro-nieruchomosci-tours-jsx" */),
  "component---src-pages-biuro-nieruchomosci-wynajem-nieruchomosci-jsx": () => import("./../../../src/pages/biuro-nieruchomosci/wynajem-nieruchomosci.jsx" /* webpackChunkName: "component---src-pages-biuro-nieruchomosci-wynajem-nieruchomosci-jsx" */),
  "component---src-pages-biuro-nieruchomosci-zakup-nieruchomosci-jsx": () => import("./../../../src/pages/biuro-nieruchomosci/zakup-nieruchomosci.jsx" /* webpackChunkName: "component---src-pages-biuro-nieruchomosci-zakup-nieruchomosci-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-jsx": () => import("./../../../src/pages/kontakt.jsx" /* webpackChunkName: "component---src-pages-kontakt-jsx" */),
  "component---src-pages-legalizacja-pobytu-informacje-jsx": () => import("./../../../src/pages/legalizacja-pobytu/informacje.jsx" /* webpackChunkName: "component---src-pages-legalizacja-pobytu-informacje-jsx" */),
  "component---src-pages-legalizacja-pobytu-karta-pobytu-jsx": () => import("./../../../src/pages/legalizacja-pobytu/karta-pobytu.jsx" /* webpackChunkName: "component---src-pages-legalizacja-pobytu-karta-pobytu-jsx" */),
  "component---src-pages-legalizacja-pobytu-obywatelstwo-rp-jsx": () => import("./../../../src/pages/legalizacja-pobytu/obywatelstwo-rp.jsx" /* webpackChunkName: "component---src-pages-legalizacja-pobytu-obywatelstwo-rp-jsx" */),
  "component---src-pages-legalizacja-pobytu-odwolania-od-negatywnych-decyzji-jsx": () => import("./../../../src/pages/legalizacja-pobytu/odwolania-od-negatywnych-decyzji.jsx" /* webpackChunkName: "component---src-pages-legalizacja-pobytu-odwolania-od-negatywnych-decyzji-jsx" */),
  "component---src-pages-polityka-prywatnosci-jsx": () => import("./../../../src/pages/polityka-prywatnosci.jsx" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-jsx" */),
  "component---src-pages-wspolpraca-kariera-jsx": () => import("./../../../src/pages/wspolpraca/kariera.jsx" /* webpackChunkName: "component---src-pages-wspolpraca-kariera-jsx" */),
  "component---src-pages-wspolpraca-zarabiaj-z-grace-home-jsx": () => import("./../../../src/pages/wspolpraca/zarabiaj-z-grace-home.jsx" /* webpackChunkName: "component---src-pages-wspolpraca-zarabiaj-z-grace-home-jsx" */)
}

