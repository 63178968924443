import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper';
import { StaticImage } from 'gatsby-plugin-image';

const SwiperAgencyImages = () => {
  return (
    <div>
      <Swiper
        modules={[Navigation, Autoplay]}
        slidesPerView={1}
        loop
        speed={1000}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
        }}
        grabCursor={true}
        loopFillGroupWithBlank={true}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
      >
        <SwiperSlide>
          <div className='w-full h-[180px] md:h-[400px]'>
            <StaticImage
              src='../images/agencySwiper/agency3.webp'
              alt='user_image'
              className='w-full h-full'
              loading='lazy'
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='w-full h-[180px] md:h-[400px]'>
            <StaticImage
              src='../images/agencySwiper/agency4.jpg'
              alt='user_image'
              className='w-full h-full'
              loading='lazy'
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='w-full h-[180px] md:h-[400px]'>
            <StaticImage
              src='../images/agencySwiper/agency2.jpg'
              alt='user_image'
              className='w-full h-full'
              loading='lazy'
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='w-full h-[180px] md:h-[400px]'>
            <StaticImage
              src='../images/agencySwiper/unsplash_3.webp'
              alt='user_image'
              className='w-full h-full'
              loading='lazy'
            />
          </div>
        </SwiperSlide>
        <div className='swiper-button-next !text-bgColor' />
        <div className='swiper-button-prev !text-bgColor' />
      </Swiper>
    </div>
  );
};

export default SwiperAgencyImages;
