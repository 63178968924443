import React from 'react';
import { SectionTitle, SwiperSlider } from '../components';
import { FormattedMessage } from 'react-intl';

const EmployeesSection = () => {
  return (
    <section>
      <SectionTitle>
        <FormattedMessage id='workers' />
      </SectionTitle>
      <SwiperSlider />
    </section>
  );
};

export default EmployeesSection;
