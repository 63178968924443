import React from 'react';
import {
  ContactIcons,
  Footer,
  Header,
  Main,
  // ScrollToTopBtn,
  Sidebar,
} from '../components';
import SimpleLocalize from '../components/SimpleLocalize';

const Layout = (props) => {
  return (
    <SimpleLocalize {...props}>
      <Header />
      <Main>{props.children}</Main>
      <Footer />
      <Sidebar />
      <ContactIcons />
      {/* <ScrollToTopBtn /> */}
    </SimpleLocalize>
  );
};

export default Layout;
