import React, { useState } from 'react';
import { BsArrowsFullscreen } from 'react-icons/bs';
import Article from './article';

const ArticleCard = ({ article }) => {
  const [isArticleVisible, setIsArticleVisible] = useState(false);
  const { title, image, paragraph1, date } = article;

  return (
    <section className='flex flex-col lg:flex-row max-h-max w-full min-h-[200px] [&:not(:last-child)]:mb-[100px] [&:not(:last-child)]:lg:mb-[150px]'>
      <div className='flex flex-col justify-between h-auto'>
        <div className='flex flex-col mb-[15px] justify-between'>
          <h2 className='text-2xl text-primaryColor'>{title}</h2>
          <span className='text-sm'>
            Data publikacji:
            <strong className='ml-[5px]'>{date}</strong>
          </span>
        </div>
        <p
          className='article-content'
          dangerouslySetInnerHTML={{
            __html: paragraph1.substring(0, 330).trim().concat('...'),
          }}
        />
        <button
          type='button'
          onClick={() => setIsArticleVisible(true)}
          className='border border-primaryColor max-w-max px-6 py-2 mt-[10px] flex gap-2 items-center'
        >
          Otwórz
          <BsArrowsFullscreen className='text-xl' />
        </button>
      </div>
      <img
        src={image.url}
        alt={`${title} - article`}
        className='aspect-video w-full h-full max-h-[300px] rounded-lg mt-[10px] lg:flex-row lg:w-[40%] lg:mt-0 lg:ml-[30px] object-cover'
      />
      <Article
        article={article}
        isArticleVisible={isArticleVisible}
        setIsArticleVisible={setIsArticleVisible}
      />
    </section>
  );
};

export default ArticleCard;
