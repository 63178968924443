import Img1 from '../images/usługi/inwestycje.webp';
import Img2 from '../images/usługi/zakup.webp';
import Img3 from '../images/usługi/doradztwo.webp';
import Img4 from '../images/usługi/sprzedaż.webp';
import Img5 from '../images/usługi/wynajem.webp';
import Img6 from '../images/usługi/działki.webp';

export const services = [
  {
    name: 'sprzedaż nieruchomości',
    nameEN: 'Sell your property',
    nameUA: 'Продаж нерухомості',
    url: '/biuro-nieruchomosci/sprzedaz-nieruchomosci',
    image: Img4,
  },
  {
    name: 'zakup nieruchomości',
    nameEN: 'Purchase property',
    nameUA: 'Купівля нерухомості',
    url: '/biuro-nieruchomosci/zakup-nieruchomosci',
    image: Img2,
  },
  {
    name: 'wynajem nieruchomości',
    nameEN: 'Property lease',
    nameUA: 'Оренда нерухомості',
    url: '/biuro-nieruchomosci/wynajem-nieruchomosci',
    image: Img5,
  },
  {
    name: 'inwestycje w nieruchomości',
    nameEN: 'real estate investments',
    nameUA: 'інвестиції в нерухомість',
    url: '/biuro-nieruchomosci/inwestycja-w-nieruchomosci',
    image: Img1,
  },
  {
    name: 'sprzedaż działek',
    nameEN: 'sale of plots',
    nameUA: 'продаж ділянок',
    url: '/biuro-nieruchomosci/sprzedaz-dzialek',
    image: Img6,
  },
  {
    name: 'doradztwo kredytowe',
    nameEN: 'Credit advisory',
    nameUA: 'Консультація з кредитних питань',
    url: '/biuro-nieruchomosci/doradztwo-kredytowe',
    image: Img3,
  },
];
