import React from 'react';
import SectionTitle from './sectionTitle';

const FindHomeSection = () => {
  return (
    <section>
      <SectionTitle>Znajdź swój wymarzony dom</SectionTitle>
      <div className='flex-col md:flex-row flex gap-5'>
        <div className='w-full flex flex-col justify-between text-md lg:text-lg'>
          <p className='pb-5'>
            Jesteśmy młodym biurem nieruchomości świadczącym kompleksowe usługi
            w zakresie zakupu i sprzedaży mieszkań, domów i działek. Pomożemy Ci
            znaleźć wymarzony dom, mieszkanie, lokal lub biuro.
          </p>
          <p className='pb-5'>
            Jednocześnie chcemy wywrzeć pozytywny wpływ na otoczenie, w którym
            zamieszkasz.
          </p>
          <p className='py-5'>
            Wybierając nasze biuro możesz wesprzeć lokalne akcje charytatywne
            dzięki naszemu zaangażowaniu w rozwój prowadzonej przez nas
            Fundacji. Tworzymy obecnie program cyklicznego wsparcia wybranych
            organizacji i mamy ambitne plany na dalszy rozwój naszej
            działalności.
          </p>
          <a
            href='https://oferty.gracehome.pl/'
            target='_blank'
            rel='noopener noreferrer'
            className='py-3 w-max px-8 cursor-pointer banner-button border border-primaryColor bg-primaryColor hover:bg-primaryColor text-white transition-all duration-500 uppercase'
          >
            sprawdż nasze oferty
          </a>
        </div>
      </div>
    </section>
  );
};

export default FindHomeSection;
