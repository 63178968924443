import React from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';

const query = `
{
  allEmployees {
    name
    id
    jobPosition
    mail
    number
    photo {
      url
    }
  }
}
`;

const SwiperSlider = () => {
  const { isLoading, data, isError, error } = useQuery(['employees'], () =>
    axios
      .post(
        'https://graphql.datocms.com/',
        {
          query,
        },
        {
          headers: {
            authorization: `Bearer ${process.env.GATSBY_DATO_CMS_KEY}`,
          },
        },
      )
      .then(({ data: { data } }) => {
        return data.allEmployees;
      }),
  );

  if (isLoading) {
    return (
      <div className='w-full h-[300px] flex justify-center items-center flex-col'>
        <p className='text-xl'>Loading...</p>
      </div>
    );
  }

  if (isError) {
    return (
      <div className='w-full h-[300px] flex justify-center items-center flex-col'>
        <p className='text-xl'>{error.message}</p>
      </div>
    );
  }

  return (
    <Swiper
      modules={[Navigation]}
      spaceBetween={25}
      slidesPerView={3}
      loop
      grabCursor={true}
      loopFillGroupWithBlank={true}
      navigation={{
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }}
      breakpoints={{
        0: {
          slidesPerView: 1,
        },
        590: {
          slidesPerView: 2,
        },
        950: {
          slidesPerView: 3,
        },
      }}
    >
      {data.map(({ id, name, jobPosition, photo, mail, number }) => (
        <SwiperSlide key={id}>
          <div className='flex flex-col justify-center items-center text-center gap-10 select-none'>
            <div className='w-[145px] h-[145px] bg-hoverColor rounded-full shadow-lg'>
              <img
                src={photo.url}
                alt='user_image'
                className='w-[145px] h-[145px] object-cover rounded-full border-2 border-hoverColor'
                loading='lazy'
              />
            </div>
            <div>
              <span className='font-bold text-md xl:text-lg mb-4 block'>
                {name}
              </span>
              {jobPosition ? (
                <p className='mb-2 uppercase text-hoverColor'>{jobPosition}</p>
              ) : null}
              {mail ? (
                <a href={`mailto:${mail}`} className='mb-1 block text-sm'>
                  {mail}
                </a>
              ) : null}
              {number ? (
                <a href={`tel:+${number}`} className='mb-1 block text-sm'>
                  {number}
                </a>
              ) : null}
            </div>
          </div>
        </SwiperSlide>
      ))}
      <div className='swiper-button-next !text-primaryColor' />
      <div className='swiper-button-prev !text-primaryColor' />
    </Swiper>
  );
};

export default SwiperSlider;
