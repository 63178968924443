import React, { useState } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import LinkTranslated from './LinkTranslated';
import { useIntl } from 'react-intl';

const HoverDropdown = ({ page, pageEN, pageUA, links }) => {
  const [open, setOpen] = useState(false);

  const intl = useIntl();
  const language = intl.locale;

  const openSubmenu = (e) => {
    console.log(e.target);
    if (e.keyCode === 13) {
      setOpen(true);
    }
  };

  const closeSubmenu = (e) => {
    if (e.target.tagName.toLowerCase() === 'a' && e.keyCode === 13) {
      setOpen(false);
    }
  };

  return (
    <div
      className='relative'
      onMouseLeave={() => setOpen(false)}
      onKeyDown={closeSubmenu}
    >
      <button
        onMouseOver={() => setOpen(true)}
        onKeyDown={openSubmenu}
        className='flex items-center p-2 rounded-md'
      >
        <span className='mr-1 uppercase'>
          {language === 'en' ? pageEN : language === 'ua' ? pageUA : page}
        </span>
        <FiChevronDown />
      </button>
      <ul
        className={`absolute right-0 w-full min-w-[260px] py-6 mt-0 rounded-lg shadow-xl transition-all bg-primaryColor  ${
          open ? 'visible opacity-100' : 'invisible opacity-0'
        }`}
      >
        {page === 'biuro nieruchomości' ? (
          <h3 className='text-hoverColor px-4 pb-3 text-xl'>
            Biuro Nieruchomości <br /> Grace Home
          </h3>
        ) : page === 'legalizacja pobytu' ? (
          <h3 className='text-hoverColor px-4 pb-3 text-xl'>
            legalizacja pobytu <br /> Grace home consulting
          </h3>
        ) : (
          <h3 className='text-hoverColor px-4 pb-3 text-xl'>{page}</h3>
        )}
        {links.map(({ label, labelEN, labelUA, url }) => {
          return (
            <li
              key={url}
              className='flex items-center hover:bg-hoverColor uppercase transition-all w-full'
            >
              <LinkTranslated
                className='text-bgColor w-full h-full px-4 py-3 underline-offset-4'
                href={url}
              >
                {language === 'en'
                  ? labelEN
                  : language === 'ua'
                  ? labelUA
                  : label}
              </LinkTranslated>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default HoverDropdown;
