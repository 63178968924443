import React from 'react';
import { motion } from 'framer-motion';
import { useLocation } from '@reach/router';

const Main = ({ children }) => {
  const location = useLocation();

  return (
    <motion.main
      className='min-h-[calc(100vh-100px)]'
      key={location.pathname}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {children}
    </motion.main>
  );
};

export default Main;
