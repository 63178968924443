import React, { useRef, useEffect } from 'react';
import useIntersectionObserver from '../hooks/useIntersectionObserver';
import { services } from '../utils/services';
import SectionTitle from './sectionTitle';
import LinkTranslated from './LinkTranslated';
import { useIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';

const ServicesSection = () => {
  const targets = useRef(new Set());
  const [entries, setObservedNodes] = useIntersectionObserver({
    threshold: 0.5,
  });
  const intl = useIntl();
  const language = intl.locale;

  useEffect(() => {
    setObservedNodes(() => [...targets.current]);
  }, [setObservedNodes]);

  useEffect(() => {
    for (const entry of entries) {
      if (entry.isIntersecting) {
        entry.target.classList.add('appear');

        setObservedNodes((observedNodes) =>
          observedNodes.filter((node) => node !== entry.target),
        );
      }
    }
  }, [entries, setObservedNodes]);

  return (
    <section>
      <SectionTitle>
        <FormattedMessage id='office-services' />
      </SectionTitle>
      <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5'>
        {services.map(({ url, name, nameEN, nameUA, image }) => {
          return (
            <LinkTranslated href={url} key={url}>
              <div
                ref={(element) => targets.current.add(element)}
                key={name}
                className='card'
              >
                <div className='w-full h-[300px] flex flex-col justify-center items-center group'>
                  <div className='min-h-[20%] flex items-center justify-center uppercase border group-hover:border-primaryColor border-primaryColor/20 transition w-full'>
                    <h3 className='text-primaryColor'>
                      {language === 'en'
                        ? nameEN
                        : language === 'ua'
                        ? nameUA
                        : name}
                    </h3>
                  </div>
                  <div className='flex w-full overflow-hidden'>
                    <img
                      key={name}
                      src={image}
                      className='w-full h-full object-cover'
                      alt={name}
                      loading='lazy'
                    />
                  </div>
                </div>
              </div>
            </LinkTranslated>
          );
        })}
      </div>
    </section>
  );
};

export default ServicesSection;
