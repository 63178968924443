import React from 'react';

const Title = ({ children }) => {
  return (
    <div className='bg-hoverColor'>
      <div className='bg-bgColor relative top-[-6px] left-[-6px]'>
        <h1 className='text-2xl md:text-5xl font-bold uppercase text-center border-2 py-4 text-bgColor border-primaryColor'>
          <span>{children}</span>
        </h1>
      </div>
    </div>
  );
};

export default Title;
