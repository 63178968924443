import React from 'react';

const TextWrapper = ({ children }) => {
  return (
    <section className='flex flex-col gap-6 text-md xl:text-lg'>
      {children}
    </section>
  );
};

export default TextWrapper;
