import React, { useState, useEffect } from 'react';
import '/node_modules/flag-icons/css/flag-icons.min.css';
import { navigate } from '@gatsbyjs/reach-router';
import { useIntl } from 'react-intl';

const SelectLanguage = () => {
  const intl = useIntl();
  const language = intl.locale;
  const [selectedLang, setSelectedLang] = useState(language);

  const languageBasedFlag = () => {
    if (selectedLang === 'pl') {
      return <span className='fi fi-pl mr-2' />;
    } else if (selectedLang === 'en') {
      return <span className='fi fi-gb mr-2' />;
    } else if (selectedLang === 'ua') {
      return <span className='fi fi-ua mr-2' />;
    }
  };

  const handleChange = (e) => {
    const lang = e.target.value;
    setSelectedLang(lang);
  };

  useEffect(() => {
    languageBasedFlag();
    if (selectedLang === 'pl') {
      navigate('/');
    } else if (selectedLang === 'en') {
      navigate('/en/');
    } else if (selectedLang === 'ua') {
      navigate('/ua/');
    }
    // eslint-disable-next-line
  }, [language, selectedLang]);

  return (
    <div className='flex items-center justify-center'>
      {languageBasedFlag()}
      <select
        className='border text-primaryColor text-sm focus:ring-hoverColor focus:border-hoverColor block w-max-w h-max-h px-2 py-1 bg-transparent border-gray-600 cursor-pointer'
        value={language}
        onChange={handleChange}
      >
        <option value='pl'>PL</option>
        <option value='en'>EN</option>
        <option value='ua'>UA</option>
      </select>
    </div>
  );
};

export default SelectLanguage;
