import React from 'react';
import LinkTranslated from './LinkTranslated';

const FooterLinkItem = ({
  link: { label, labelEN, labelUA, url },
  language,
}) => {
  if (label === 'oferty') {
    return (
      <a
        href={url}
        target='_blank'
        rel='noopener noreferrer'
        className='cursor-pointer text-primaryColor hover:text-hoverColor first-letter:capitalize py-[2px] mb-1'
      >
        {language === 'en' ? labelEN : language === 'ua' ? labelUA : label}
      </a>
    );
  }

  return (
    <LinkTranslated
      href={url}
      className='cursor-pointer text-primaryColor hover:text-hoverColor first-letter:capitalize py-[2px] mb-1 underline-offset-4'
    >
      {language === 'en' ? labelEN : language === 'ua' ? labelUA : label}
    </LinkTranslated>
  );
};

export default FooterLinkItem;
