import React, { useState } from 'react';
import { RiArrowRightSLine } from 'react-icons/ri';
import LinkTranslated from './LinkTranslated';

const SidebarItem = ({ item, language }) => {
  const [open, setOpen] = useState(false);
  const { page, pageEN, pageUA, url, links } = item;

  if (links) {
    return (
      <div className='mb-2'>
        <button
          className='flex items-center text-left cursor-pointer uppercase font-bold'
          onClick={() => setOpen((prevState) => !prevState)}
        >
          {language === 'en' ? pageEN : language === 'ua' ? pageUA : page}
          {page && (
            <RiArrowRightSLine
              className={`text-lg transition ${
                open ? 'rotate-90' : 'rotate-0'
              }`}
            />
          )}
        </button>
        <div
          className={`text-sm flex flex-col ${
            open ? 'h-auto visible' : 'h-0 hidden'
          }`}
        >
          {links.map(({ label, labelEN, labelUA, url }) => (
            <LinkTranslated
              key={url}
              href={url}
              className='pl-4 py-1 cursor-pointer'
            >
              {language === 'en'
                ? labelEN
                : language === 'ua'
                ? labelUA
                : label}
            </LinkTranslated>
          ))}
        </div>
      </div>
    );
  } else {
    return (
      <div className='mb-2 font-bold'>
        {url && page === 'oferty' ? (
          <a
            href={url}
            target='_blank'
            rel='noopener noreferrer'
            className='text-logoColor'
          >
            {language === 'en' ? pageEN : language === 'ua' ? pageUA : page}
          </a>
        ) : (
          <LinkTranslated href={url} className='cursor-pointer'>
            {language === 'en' ? pageEN : language === 'ua' ? pageUA : page}
          </LinkTranslated>
        )}
      </div>
    );
  }
};

export default SidebarItem;
