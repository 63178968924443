import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

function Seo({ description, title, children }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `,
  );

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = title
    ? `Grace Home - ${title}`
    : site.siteMetadata?.title;

  return (
    <>
      {/* <html lang='pl' /> */}
      <title>{defaultTitle}</title>
      <meta name='description' content={metaDescription} />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={metaDescription} />
      <meta property='og:type' content='website' />
      <meta name='twitter:card' content='summary' />
      <meta name='twitter:creator' content={site.siteMetadata?.author || ``} />
      <meta name='twitter:title' content={title} />
      <meta name='twitter:description' content={metaDescription} />
      {children}
    </>
  );
}

export default Seo;
