import React, { useEffect } from 'react';
import { useGlobalContext } from '../context/context';
import { StaticImage } from 'gatsby-plugin-image';
import { navlinks } from '../utils/nav-links';
import SidebarItem from './SidebarItem';
import { useLocation } from '@reach/router';
import { useIntl } from 'react-intl';

const Sidebar = () => {
  const { isSidebarOpen, closeSidebar } = useGlobalContext();
  const location = useLocation();
  const intl = useIntl();
  const language = intl.locale;

  // close sidebar on every route change
  useEffect(() => {
    closeSidebar();
    // eslint-disable-next-line
  }, [location.pathname]);

  const baseClassName =
    'fixed lg:hidden top-0 left-0 h-screen w-[250px] transform bg-bgColor duration-500 z-40 border-r overflow-auto border-r-primaryColor';

  return (
    <aside
      className={
        isSidebarOpen
          ? `${baseClassName} translate-x-0`
          : `${baseClassName} -translate-x-full `
      }
    >
      <div className='flex items-center h-[100px] px-[2vw]'>
        <StaticImage
          src='../images/GraceHomeLogoSmall.png'
          alt='logo'
          className='w-[70px] h-[70px] min-w-[70px]'
          placeholder='transparent'
        />
      </div>
      <div className='mt-5 px-[2vw] text-primaryColor uppercase'>
        {navlinks.map((item) => (
          <SidebarItem key={item.page} item={item} language={language} />
        ))}
      </div>
    </aside>
  );
};

export default Sidebar;
