import React from 'react';
import { useGlobalContext } from '../context/context';

const ToggleBtn = () => {
  const { isSidebarOpen, toggleSidebar } = useGlobalContext();

  return (
    <button
      className={
        isSidebarOpen
          ? 'lg:hidden relative group overflow-hidden active z-40'
          : 'lg:hidden relative group overflow-hidden'
      }
      onClick={toggleSidebar}
      type='button'
      aria-label='toggle navigation'
    >
      <div className='flex flex-col justify-between w-[28px] h-[28px] transform transition-all duration-300 origin-center overflow-hidden'>
        <div className='bg-primaryColor h-[2px] w-8 transform transition-all duration-300 origin-left group-[.active]:translate-x-10' />
        <div className='bg-primaryColor h-[2px] w-8 transform transition-all duration-300 group-[.active]:translate-x-10 delay-75' />
        <div className='bg-primaryColor h-[2px] w-8 transform transition-all duration-300 origin-left group-[.active]:translate-x-10 delay-150' />
        <div className='absolute items-center justify-between transform transition-all duration-500 top-3.5 -left-0.5 -translate-x-10 group-[.active]:translate-x-0 flex w-0 group-[.active]:w-12'>
          <div className='bg-primaryColor absolute h-[2px] w-8 transform transition-all duration-500 rotate-0 delay-300 group-[.active]:rotate-45' />
          <div className='bg-primaryColor  absolute h-[2px] w-8 transform transition-all duration-500 -rotate-0 delay-300 group-[.active]:-rotate-45' />
        </div>
      </div>
    </button>
  );
};

export default ToggleBtn;
