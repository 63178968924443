import React from 'react';
import { Location } from '@reach/router';
import { getCurrentPageLanguage, getTranslatedPath } from '../utils/linkUtils';
import { Link } from 'gatsby';

const LinkTranslated = ({ children = [], className = '', href = '/' }) => {
  return (
    <Location>
      {(locationProps) => {
        // console.log(locationProps);
        const { pathname = '' } = locationProps.location;
        // console.log(pathname);
        return (
          <Link
            className={className}
            activeStyle={{ textDecoration: 'underline' }}
            to={getTranslatedPath(pathname, href)}
            hrefLang={getCurrentPageLanguage(pathname)}
          >
            {children}
          </Link>
        );
      }}
    </Location>
  );
};

export default LinkTranslated;
