import React from 'react';
import {
  AiOutlinePhone,
  AiOutlineMail,
  AiOutlineWhatsApp,
} from 'react-icons/ai';
import { HiOutlineMapPin } from 'react-icons/hi2';
import { FaTelegramPlane } from 'react-icons/fa';

const ContactIcons = () => {
  return (
    <div className='fixed top-[50%] translate-y-[-50%] z-20 right-0 flex flex-col bg-logoColor'>
      <a
        className='w-[35px] h-[35px] md:w-[50px] md:h-[50px] flex justify-center items-center cursor-pointer'
        href='tel:+48 605 505 308'
        aria-label='contact number'
      >
        <AiOutlinePhone className='w-[70%] h-[70%]  text-bgColor transition hover:text-primaryColor' />
      </a>
      <a
        className='w-[35px] h-[35px] md:w-[50px] md:h-[50px] flex justify-center items-center cursor-pointer'
        href='mailto:biuro@gracehome.pl'
        aria-label='email'
      >
        <AiOutlineMail className='w-[70%] h-[70%] text-bgColor transition hover:text-primaryColor' />
      </a>
      <a
        className='w-[35px] h-[35px] md:w-[50px] md:h-[50px] flex justify-center items-center cursor-pointer'
        href='https://t.me/+ODbu8MdpTmszMzg8'
        aria-label='telegram'
      >
        <FaTelegramPlane className='w-[70%] h-[70%] text-bgColor transition hover:text-primaryColor' />
      </a>
      <a
        className='w-[35px] h-[35px] md:w-[50px] md:h-[50px] flex justify-center items-center cursor-pointer'
        href='https://wa.me/+48508000455'
        aria-label='whatsapp'
      >
        <AiOutlineWhatsApp className='w-[70%] h-[70%] text-bgColor transition hover:text-primaryColor' />
      </a>
      <a
        className='w-[35px] h-[35px] md:w-[50px] md:h-[50px] flex justify-center items-center cursor-pointer'
        href='https://goo.gl/maps/JaujnbkmqFU5vd9f8'
        target='_blanc'
        rel='noopener noreferrer'
        aria-label='google maps'
      >
        <HiOutlineMapPin className='w-[70%] h-[70%] text-bgColor transition hover:text-primaryColor' />
      </a>
    </div>
  );
};

export default ContactIcons;
