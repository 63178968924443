import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AnimatePresence } from 'framer-motion';
import './src/styles/global.css';
import { AppContextProvider } from './src/context/context';
import { PaginationProvider } from './src/context/paginateContext';
import Layout from './src/layouts/layout';
const queryClient = new QueryClient({});

export const wrapPageElement = ({ element, props }) => {
  return (
    <AnimatePresence>
      <Layout {...props}>{element}</Layout>
    </AnimatePresence>
  );
};

export const wrapRootElement = ({ element }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <PaginationProvider>
        <AppContextProvider>{element}</AppContextProvider>
      </PaginationProvider>
    </QueryClientProvider>
  );
};
