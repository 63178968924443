import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import emailjs from '@emailjs/browser';
import React, { useState } from 'react';
import { formSchema } from '../utils/form-schema';
import { Loader } from '../components';
import LinkTranslated from './LinkTranslated';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';

const Form = () => {
  const [activeLoader, setActiveLoader] = useState(false);

  const intl = useIntl();
  const language = intl.locale;

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const submitForm = async (data) => {
    // EmailJs Start
    setActiveLoader(true);
    try {
      const result = await emailjs.send(
        `${process.env.GATSBY_SERVICE_ID}`,
        `${process.env.GATSBY_TEMPLATE_ID}`,
        data,
        `${process.env.GATSBY_PUBLIC_KEY}`,
      );
      console.log(result.text);
      setActiveLoader(false);
      alert('Thank You, the form has been successfully sent!');
      reset();
    } catch (error) {
      console.log(error);
      setActiveLoader(false);
      alert(`Something went wrong :( \n Error: ${error.text} \n Try again!`);
    }
  };
  // EmailJs End

  return (
    <>
      <div className='flex flex-col text-center w-full '>
        <form
          onSubmit={handleSubmit(submitForm)}
          className='flex flex-col gap-2 w-full'
        >
          <div className='flex flex-col sm:flex-row gap-2'>
            <div className='w-full'>
              <input
                type='text'
                placeholder={
                  language === 'en'
                    ? 'Name'
                    : language === 'ua'
                    ? 'назва'
                    : 'Imię'
                }
                {...register('firstName')}
                name='firstName'
                className='w-full p-2 focus:outline-primaryColor bg-transparent border-primaryColor border'
              />
              <span>{errors.firstName?.message}</span>
            </div>
            <div className='w-full'>
              <input
                type='text'
                placeholder={
                  language === 'en'
                    ? 'Last name (optional)'
                    : language === 'ua'
                    ? `прізвище (${`необов'язковий`})`
                    : 'Nazwisko'
                }
                {...register('lastName')}
                name='lastName'
                className='w-full p-2 focus:outline-primaryColor bg-transparent border-primaryColor border'
              />
              <span>{errors.lastName?.message}</span>
            </div>
          </div>
          <div className='flex flex-col sm:flex-row gap-2'>
            <div className='w-full'>
              <input
                type='email'
                placeholder='Email'
                {...register('email')}
                name='email'
                className='w-full p-2 focus:outline-primaryColor bg-transparent border-primaryColor border'
              />
              <span>{errors.email?.message}</span>
            </div>
            <div className='w-full'>
              <input
                type='text'
                placeholder={
                  language === 'en'
                    ? 'Number (optional)'
                    : language === 'ua'
                    ? `номер (${`необов'язковий`})`
                    : 'Numer'
                }
                {...register('phone')}
                name='phone'
                className='w-full p-2 focus:outline-primaryColor bg-transparent border-primaryColor border'
              />
              <span>{errors.number?.message}</span>
            </div>
          </div>
          <textarea
            rows='7'
            placeholder={
              language === 'en'
                ? 'Your message...'
                : language === 'ua'
                ? 'Ваше повідомлення...'
                : 'Twoja wiadomość...'
            }
            {...register('message')}
            name='message'
            className='p-2 focus:outline-primaryColor bg-transparent border-primaryColor border'
          ></textarea>
          <span>{errors.message?.message}</span>
          <div className='p-2 w-full'>
            <input
              type='checkbox'
              name='consent'
              id='consent'
              className='float-left w-[12px] h-[12px] mr-1 my-[2px]'
              {...register('consent')}
            />
            <label
              htmlFor='consent'
              className='text-xs block text-justify leading-[16px] '
            >
              <FormattedMessage id='form-policy-p1' />{' '}
              <LinkTranslated
                href='/polityka-prywatnosci'
                className='text-hoverColor'
              >
                {language === 'en'
                  ? 'PRIVACY POLICY'
                  : language === 'ua'
                  ? 'ОЛІТИКА КОНФІДЕНЦІЙНОСТІ'
                  : 'POLITYKĘ PRYWATNOŚCI'}
                .
              </LinkTranslated>{' '}
              <FormattedMessage id='form-policy-p2' />
            </label>
            <span className='invalid-feedback mt-2 block'>
              {errors.consent?.message}
            </span>
          </div>
          <button
            type='submit'
            className='bg-primaryColor p-2 transition text-bgColor hover:text-hoverColor uppercase'
          >
            {language === 'en'
              ? 'SEND'
              : language === 'ua'
              ? 'НАДІСЛАТИ'
              : 'WYŚLIJ'}
          </button>
        </form>
      </div>
      {activeLoader ? (
        <div className='bg-bgDarkBlue fixed top-0 left-0 right-0 bottom-0 z-40 grid place-items-center backdrop-blur-[6px] bg-opacity-40'>
          <Loader />
          <p>Sending...</p>
        </div>
      ) : null}
    </>
  );
};

export default Form;
