import React from 'react';
import { footerLinks } from '../utils/footer-links';
import { FooterLinkItem, FooterContact } from '../components';
import { AiOutlineCopyrightCircle } from 'react-icons/ai';
import { useIntl } from 'react-intl';

const Footer = () => {
  const intl = useIntl();
  const language = intl.locale;

  return (
    <footer className='text-lg bg-bgColor border-t border-t-primaryColor flex flex-col items-center justify-center text-primaryColor uppercase'>
      <section className='flex flex-wrap items-start w-full max-w-[1200px] py-6 px-[3vw]'>
        <div className='flex flex-col max-w-[260px] lg:min-w-[22%] pr-4 py-4 text-[13px]'>
          <h3 className='text-lg mb-2 text-primaryColor'>
            {language === 'en'
              ? 'CONTACT'
              : language === 'ua'
              ? 'контакт'
              : 'KONTAKT'}
          </h3>
          <FooterContact language={language} />
        </div>
        {footerLinks.map(({ title, titleEN, titleUA, links }) => (
          <div
            className='flex flex-col max-w-[260px] lg:min-w-[22%] pr-4 py-4 text-[13px]'
            key={title}
          >
            <h3 className='text-lg mb-2 text-primaryColor'>
              {language === 'en'
                ? titleEN
                : language === 'ua'
                ? titleUA
                : title}
            </h3>
            {links.map((link, index) => (
              <FooterLinkItem key={index} link={link} language={language} />
            ))}
          </div>
        ))}
      </section>
      <div className='border-t border-t-primaryColor w-full flex justify-center'>
        <p className='p-4 text-[13px] flex text-center items-center'>
          Copyright <AiOutlineCopyrightCircle className='mx-1' />
          {new Date().getFullYear()}
          <b className='ml-1'>GraceHome</b>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
