import React from 'react';
import { Nav, ToggleBtn } from '../components';
import { StaticImage } from 'gatsby-plugin-image';
import SelectLanguage from './selectLanguage';
import LinkTranslated from './LinkTranslated';

const Header = () => {
  return (
    <header className='fixed bg-bgColor border-b border-b-primaryColor top-0 left-0 right-0 flex px-[5vw] h-[100px] items-center justify-between z-20 font-bold text-black'>
      <LinkTranslated href='/'>
        <StaticImage
          src='../images/GraceHomeLogoSmall.png'
          alt='logo'
          className='w-[70px] h-[70px] min-w-[70px] select-none'
          placeholder='transparent'
        />
      </LinkTranslated>
      <Nav />
      <SelectLanguage />
      <ToggleBtn />
    </header>
  );
};

export default Header;
