import React from 'react';
import { navlinks } from '../utils/nav-links';
import HoverDropdown from './hoverDropdown';
import LinkTranslated from './LinkTranslated';
import { useIntl } from 'react-intl';

const Nav = () => {
  const intl = useIntl();
  const language = intl.locale;

  return (
    <nav className='hidden lg:flex w-full h-full justify-center items-center text-primaryColor'>
      {navlinks.map(({ page, url, pageEN, pageUA, links }) => {
        return (
          <ul
            key={page}
            className='flex nav-links justify-center items-center text-[13px] xl:text-[15px] uppercase'
          >
            <li>
              {url && page === 'oferty' ? (
                <a
                  href={url}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='hover:underline underline-offset-4 cursor-pointer py-3 px-2 flex text-center text-logoColor'
                >
                  {language === 'en'
                    ? pageEN
                    : language === 'ua'
                    ? pageUA
                    : page}
                </a>
              ) : url && page !== 'oferty' ? (
                <LinkTranslated
                  href={url}
                  className='hover:underline underline-offset-4 cursor-pointer py-3 px-2 flex text-center'
                >
                  {language === 'en'
                    ? pageEN
                    : language === 'ua'
                    ? pageUA
                    : page}
                </LinkTranslated>
              ) : (
                <HoverDropdown
                  page={page}
                  pageEN={pageEN}
                  pageUA={pageUA}
                  links={links}
                />
              )}
            </li>
          </ul>
        );
      })}
    </nav>
  );
};

export default Nav;
