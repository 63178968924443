import React from 'react';
import { AiOutlineClose } from 'react-icons/ai';

const Article = ({ article, isArticleVisible, setIsArticleVisible }) => {
  const { htmltext } = article;

  return (
    <article
      className={`bg-primaryColor/90 fixed top-0 left-0 bottom-0 right-0 z-50 backdrop-blur overflow-y-auto ${
        isArticleVisible ? 'block' : 'hidden'
      }`}
    >
      <div className='p-6 md:p-10 flex flex-col justify-center items-center text-center text-bgColor w-full max-w-[1200px] gap-8 mx-auto'>
        <div className='flex justify-end w-full'>
          <button type='button' onClick={() => setIsArticleVisible(false)}>
            <AiOutlineClose className='text-4xl' />
          </button>
        </div>
        {htmltext ? (
          <div
            className='html-content'
            dangerouslySetInnerHTML={{ __html: htmltext }}
          />
        ) : null}
      </div>
    </article>
  );
};

export default Article;
