import React from 'react';
import { FreeGoogleMaps, SectionTitle } from '../components';
import { FormattedMessage } from 'react-intl';

const MapSection = () => {
  return (
    <section>
      <SectionTitle>
        <FormattedMessage id='map' />
      </SectionTitle>
      <FreeGoogleMaps />
    </section>
  );
};

export default MapSection;
