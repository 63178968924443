import React from 'react';

const FooterContact = ({ language }) => {
  return (
    <>
      <span className='py-[2px]'>Grace Home</span>
      <a
        href='https://goo.gl/maps/JaujnbkmqFU5vd9f8'
        target='_blanc'
        rel='noopener noreferrer'
        className='cursor-pointer text-primaryColor hover:text-hoverColor py-[2px] mb-1'
      >
        ul. Piękna 24/26A, <br /> 00-549 Warszawa
      </a>
      <a
        href='tel:+48 605 505 308'
        className='cursor-pointer text-primaryColor hover:text-hoverColor py-[2px] mb-1'
      >
        tel: +48 605 505 308
      </a>
      <a
        href='mailto:biuro@gracehome.pl'
        className='cursor-pointer text-primaryColor hover:text-hoverColor py-[2px] mb-1'
      >
        biuro@gracehome.pl
      </a>
      <span className='py-[2px]'>NIP: 5832972526</span>
      <span className='py-[2px]'>REGON: 381350561</span>
      <span className='py-[2px]'>
        {language === 'en'
          ? 'Opening hours'
          : language === 'ua'
          ? 'години роботи'
          : 'Godziny otwarcia'}
        : Pn.-Pt. 9:00 - 20:00
      </span>
    </>
  );
};

export default FooterContact;
