import React from 'react';

const FreeGoogleMaps = () => {
  return (
    <iframe
      src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6912.876487819022!2d21.012762742283172!3d52.22392929430258!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471eccefbf8b34b1%3A0x35f70ac4be27ab20!2sPi%C4%99kna%2024%2F26%2C%2000-549%20Warszawa!5e0!3m2!1sen!2spl!4v1678367470948!5m2!1sen!2spl'
      allowFullScreen=''
      loading='lazy'
      className='w-full max-w-[100%] h-[300px]'
      title='Google Maps - Grace Home'
    />
  );
};

export default FreeGoogleMaps;
